import { AuthContextProps } from "react-oidc-context";
import create from "zustand";
import { useErrorStore } from "./useErrorStore";
import { DataTableStoreType } from "./usePartnerStore";

export interface AuditLogStoreType extends DataTableStoreType {

}

export const useAuditLogStore = create<AuditLogStoreType>((set, get) => ({

    error: false,

    setError: (error) => {
        set((s) => ({
            error
        }));
    },

    loading: false,

    setLoading: (loading) => {
        set((s) => ({
            loading
        }));
    },

    attributes: [],

    setAttributes: (attributes) => {
        set((s) => ({
            attributes,
        }));
    },

    records: [],

    refresh: (auth: AuthContextProps) => {
        get().setLoading(true);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${auth.user?.access_token}`);

        // Get attributes
        const visibleAttributes = get().attributes.filter(i => i.show);
        const attributes = visibleAttributes.join(",");

        fetch(`${(window as any)._env_.API_URL}auditlog?select=${attributes}`, {
            headers: headers
        })
            .then((data) => {
                return data.json();
            }, (err) => {
                get().setError(true);
                const addError = useErrorStore.getState().addError;
                addError("Could not retrieve the table information. Please try again or contact the system administrator.");
            })
            .then((json: any[]) => {
                set((s) => ({
                    records: json,
                }));
            })
            .finally(() => {
                get().setLoading(false);
            });
    }
}));
