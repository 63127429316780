import { BrowserRouter, Route, Routes } from "react-router-dom"
import { CreatePartner } from "./crud/CreatePartner"
import { EditPartner } from "./crud/EditPartner"
import { AppLayout } from "./AppLayout"
import { AuditLogs } from "./pages/AuditLogs"
import { Home } from "./pages/Home"
import { Partners } from "./pages/Partners"
import { OidcSigninCallback } from "./OidcSigninCallback"

export const Router = () => {

    return <BrowserRouter>
        <Routes>
            <Route path="/oidc-signin" element={<OidcSigninCallback />} />
            <Route path="/" element={<AppLayout />}>
                <Route index element={<Home />} />
                <Route path="partners" element={<Partners />}>
                    <Route path=":partnerId" element={<EditPartner />} />
                    <Route path="create" element={<CreatePartner />} />
                </Route>
                <Route path="auditlog" element={<AuditLogs />} />
            </Route>
        </Routes>
    </BrowserRouter>
}