import create from "zustand";

export type UIStoreType = {

    /**
     * Title of the page
     */
    title: string;

    /**
     * Update the title of the current page
     */
    setTitle: (value: string) => void;
}

export const useUIStore = create<UIStoreType>((set) => ({

    title: "",

    setTitle: (value: string) => {
        set(() => ({
            title: value
        }));
    }
}));