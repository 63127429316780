import React from 'react';
import './App.css';
import { useAuth } from 'react-oidc-context';

export const Logout = () => {
    const auth = useAuth();

    const onClick = () => {
        auth.signoutRedirect();
    }

    if (auth.isAuthenticated) {
        return (
            <a href="#" className="dropdown-item" onClick={onClick}><i className="far fa-arrow-alt-circle-left"></i> Sign Out</a>
        );
    }

    return <></>;
}
