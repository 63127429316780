import { AuthContextProps, useAuth } from "react-oidc-context";
import create from "zustand";
import { Partner } from "../models/Partner";
import { DataTableAttribute } from "../ui/elements/datatable/DataTableAttribute";
import { useErrorStore } from "./useErrorStore";

export interface DataTableStoreType {

    /**
     * Whether retrieving the data is in error state
     */
    error: boolean,

    /**
     * Sets or clears the error state from the table
     */
    setError: (error: boolean) => void,

    /**
     * Whether the data in the data table is being loaded
     */
    loading: boolean;

    /**
     * Update loading state of the data table
     */
    setLoading: (loading: boolean) => void;

    /**
     * List of partner records
     */
    records: Partner[];

    /**
     * Method to refresh the data store
     */
    refresh: (auth: AuthContextProps) => void;

    /**
     * Set the attributes for the data table
     */
    setAttributes: (attributes: DataTableAttribute[]) => void;

    /**
     * List of attributes in the data table
     */
    attributes: DataTableAttribute[];
}

export interface PartnerStoreType extends DataTableStoreType {

}

export const usePartnerStore = create<PartnerStoreType>((set, get) => ({

    error: false,

    setError: (error) => {
        set((s) => ({
            error
        }));
    },

    loading: false,

    setLoading: (loading) => {
        set(() => ({
            loading
        }));
    },

    attributes: [],

    setAttributes: (attributes: DataTableAttribute[]) => {
        set((s) => ({
            attributes,
        }));
    },

    records: [],

    refresh: (auth: AuthContextProps) => {
        get().setLoading(true);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${auth.user?.access_token}`);

        // Get attributes
        const visibleAttributes = get().attributes.filter(i => i.show);
        const attributes = visibleAttributes.join(",");

        fetch(`${(window as any)._env_.API_URL}partners?select=${attributes}`, {
            headers: headers
        })
            .then((data) => {
                return data.json();
            }, (err) => {
                get().setError(true);
                const addError = useErrorStore.getState().addError;
                addError("Could not retrieve the table information. Please try again or contact the system administrator.");
            })
            .then((json: Partner[]) => {
                set((s) => ({
                    records: json,
                }));
            })
            .finally(() => {
                get().setLoading(false);
            });
    }
}));