import { useUIStore } from "../stores/useUIStore"

export const PageTitle = () => {
    const title = useUIStore(state => state.title);

    return <div className="breadcrumb-header justify-content-between">
        <div>
            <span className="main-content-title mg-b-0 mg-b-lg-1">{title}</span>
        </div>
    </div>
}