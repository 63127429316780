import { ReactChild, ReactElement, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useErrorStore } from "../../../stores/useErrorStore";
import { DataTableStoreType } from "../../../stores/usePartnerStore";
import { DataTableAttribute } from "./DataTableAttribute";

export type DataTableProps = {

    /**
     * The data store used to retrieve and show data
     */
    store: DataTableStoreType;

    /**
     * Offset of the table, when not set defaults to 0
     */
    offset?: number;

    /**
     * Limit of the table, when not set defaults to 50
     */
    limit?: number;

    /**
     * Whether to show or hide the pagination. Defaults to false
     */
    usePagination?: boolean;

    /**
     * List of buttons to show above the table
     */
    buttons?: ReactChild | ReactChild[];
}

export type DataTableState = {

    limit: number;

    attributes: DataTableAttribute[];
}

/**
 * Renders a data table in the UI
 * @param props 
 * @returns 
 */
export const DataTable = (props: DataTableProps) => {
    const auth = useAuth();
    const [state, setState] = useState<DataTableState>({
        attributes: props.store.attributes,
        limit: props.limit ?? 50,
    });

    // Get parameters for table
    const offset = props.offset ?? 0;

    // Update data on state change
    useEffect(() => {
        updateData();
    }, [state.attributes, state.limit]);

    /**
     * Update data in data table
     */
    const updateData = () => {
        if (props.store.loading) {
            return;
        }

        props.store.refresh(auth);
    }

    /**
     * Get attributes that should be shown in the UI
     * @returns
     */
    const getVisibleAttributes = () => {
        return props.store.attributes.filter(f => f.show);
    }

    /**
     * Determine the value to render in the table
     * @param attribute 
     * @param record 
     * @returns 
     */
    const getValue = (attribute: DataTableAttribute, record: any) => {

        // Get field type
        let fieldType = attribute.type
        if (!attribute.type) {
            fieldType = "text";
        }

        // Get field value
        const recordValue = record[attribute.field] as string;
        let outputValue = <>{recordValue}</>;
        switch (fieldType) {
            case "datetime":
                outputValue = <>{new Date(recordValue).toLocaleString()}</>;
                break;

            case "date":
                outputValue = <>{new Date(recordValue).toLocaleDateString()}</>;
                break;

            case "time":
                outputValue = <>{new Date(recordValue).toLocaleTimeString()}</>;
                break;

            case "actions":
                outputValue = <></>
                attribute.actions?.forEach((action) => {
                    outputValue = <>
                        {outputValue}
                        <a href="#" className={`btn btn-rounded ${action.className}`} onClick={() => { action.onClick!(record) }}>{action.title}</a>
                    </>;
                });
                break;
        }

        // Check if the field should be a hyperlink
        if (attribute.hyperlink) {
            return <a href="#" onClick={() => { attribute.onClick!(record) }}>{outputValue}</a>;
        } else {
            return outputValue;
        }
    }

    /**
     * Render a list of records
     * @param records 
     * @returns 
     */
    const getRecords = (records: any[]) => {
        const activeAttributes = getVisibleAttributes();

        return records.map((record, rowIdx) =>
            <tr key={rowIdx}>
                {activeAttributes.map((attribute, colIdx) =>
                    <th key={`${rowIdx}${colIdx}`} className="wd-20p">{getValue(attribute, record)}</th>
                )}
            </tr>
        );
    }

    /**
     * Update the limit of the page
     * @param value 
     */
    const setLimit = (value: string) => {
        setState(s => ({
            ...s,
            limit: parseInt(value)
        }));
    }

    /**
     * Render a single line table message, e.g. error, loading or no data
     * @param content 
     */
    const renderTableMessage = (content: ReactChild) => {
        const colSpan = getVisibleAttributes().length;
        return <tr><td colSpan={colSpan}>{content}</td></tr>
    }

    /**
     * Retrieve the table body
     */
    const getBody = () => {
        if (props.store.loading) {
            return renderTableMessage(<div className="spinner-border" role="status"> <span className="sr-only">Loading...</span></div>);
        }
        if (props.store.error) {
            return renderTableMessage("An error occured while retrieving the records");
        }
        if (!props.store.records || props.store.records.length === 0) {
            return renderTableMessage("No records available");
        }

        return getRecords(props.store.records);
    }

    return <div className="dataTables_wrapper dt-bootstrap5 no-footer">
        <div className="row">
            <div className="col-sm-12 col-md-6">
                {props.usePagination && <div className="dataTables_length" id="basic-datatable_length">
                    <label>Show <select onChange={(e) => setLimit(e.target.value)} value={state.limit} name="basic-datatable_length" aria-controls="basic-datatable" className="form-select form-select-sm select2" aria-hidden="true">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select> entries</label>
                </div>}
                {!props.store.error && props.buttons && <div className="dt-buttons btn-group flex-wrap">
                    {props.buttons}
                </div>}
            </div>
            <div className="col-sm-12 col-md-6">
                {/* <div id="basic-datatable_filter" className="dataTables_filter">
                    <label>
                        <input type="search" className="form-control form-control-sm " placeholder="Search..." aria-controls="basic-datatable" data-ms-editor="true" />
                    </label>
                </div> */}
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <table className="table table-hover table-bordered text-nowrap key-buttons border-bottom dataTable no-footer dtr-inline" id="auditlogs">
                    <thead>
                        <tr>
                            {getVisibleAttributes().map((attribute) =>
                                <th className="wd-20p">{attribute.label}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {getBody()}
                    </tbody>
                </table>
            </div>
        </div>
        {!props.store.loading && props.usePagination && <div className="row">
            <div className="col-sm-12 col-md-5">
                <div className="dataTables_info" role="status" aria-live="polite">Showing 31 to 40 of 57 entries</div>
            </div>
            <div className="col-sm-12 col-md-7">
                <div className="dataTables_paginate paging_simple_numbers">
                    <ul className="pagination">
                        <li className="paginate_button page-item previous"><a href="#" aria-controls="file-datatable" data-dt-idx="0" className="page-link">Previous</a></li>
                        <li className="paginate_button page-item active"><a href="#" aria-controls="file-datatable" data-dt-idx="1" className="page-link">1</a></li>
                        <li className="paginate_button page-item "><a href="#" aria-controls="file-datatable" data-dt-idx="2" className="page-link">2</a></li>
                        <li className="paginate_button page-item "><a href="#" aria-controls="file-datatable" data-dt-idx="3" className="page-link">3</a></li>
                        <li className="paginate_button page-item"><a href="#" aria-controls="file-datatable" data-dt-idx="4" className="page-link">4</a></li>
                        <li className="paginate_button page-item "><a href="#" aria-controls="file-datatable" data-dt-idx="5" className="page-link">5</a></li>
                        <li className="paginate_button page-item "><a href="#" aria-controls="file-datatable" data-dt-idx="6" className="page-link">6</a></li>
                        <li className="paginate_button page-item next"><a href="#" aria-controls="file-datatable" data-dt-idx="7" className="page-link">Next</a></li>
                    </ul>
                </div>
            </div>
        </div>}
    </div>;
}