import { AuthProvider } from "react-oidc-context";
import { AuthVerification } from "./AuthVerification";
import { useOidcConfig } from "./hooks/useOidcConfig";
import { Router } from "./Router";

/**
 * Main definition of the app
 * @returns 
 */
export const Main = () => {
    const { getOidcConfig } = useOidcConfig();

    return <AuthProvider {...getOidcConfig()}>
        <AuthVerification>
            <Router />
        </AuthVerification>
    </AuthProvider>
}
