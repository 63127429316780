import { useState } from "react";
import { Logout } from "../../Logout"
import { Profile } from "../../Profile"
import { ProfilePicture } from "../../ProfilePicture"

export const ProfileMenu = () => {
    const [show, setShow] = useState(false);

    return <li className="dropdown main-profile-menu nav nav-item nav-link ps-lg-2">
        <a href="#" onClick={() => { setShow(!show); }} className="new nav-link profile-user d-flex" data-bs-toggle="dropdown">
            <ProfilePicture />
        </a>
        <div className={`dropdown-menu ${show && "show"}`}>
            <div className="menu-header-content p-3 border-bottom">
                <Profile />
            </div>
            <a className="dropdown-item" href="profile.html"><i className="far fa-user-circle"></i>Profile</a>
            <a className="dropdown-item" href="mail-settings.html"><i className="far fa-sun"></i>  Settings</a>
            <Logout />
        </div>
    </li>
}