export type PageCardProps = {

    /**
     * Page title
     */
    title: string;

    /**
     * Page description
     */
    description?: string;

    /**
     * Children to be added on the page
     */
    children?: JSX.Element;
}

/**
 * Regular page card with title and description
 * @param props 
 * @returns 
 */
export const PageCard = (props: PageCardProps) => {
    return <div className="card-body">
        <div>
            <h4 className="card-title">{props.title}</h4>
            {props.description && <p className="text-muted card-sub-title">{props.description}</p>}
        </div>
        <div>
            {props.children}
        </div>
    </div>
}