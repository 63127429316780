import { User } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";
import { OidcUserStateType } from "../interfaces/OidcUserStateType";

export const useOidcConfig = () => {

    const getOidcConfig = (): AuthProviderProps => {

        /**
         * Redirect the user on succesfull sign in to the locaiton that is passed in the state
         * @param _user 
         * @returns 
         */
        const onSigninCallback = (_user: User | void): void => {
            if (!_user)
                return;

            // Retrieve the user state
            const userState = _user.state as OidcUserStateType;

            // Update the current window
            window.location.replace(userState.href);
        }

        const oidcConfig: AuthProviderProps = {
            authority: (window as any)._env_.OIDC_AUTHORITY as string,
            client_id: (window as any)._env_.OIDC_CLIENTID as string,
            client_secret: (window as any)._env_.OIDC_CLIENTSECRET as string,
            redirect_uri: (window as any)._env_.OIDC_REDIRECTURI as string,
            scope: "openid profile geoapps",
            loadUserInfo: true,
            automaticSilentRenew: true,
            onSigninCallback
        };

        return oidcConfig;
    };

    return {
        getOidcConfig
    }
}
