import React from 'react';
import './App.css';
import { useAuth } from 'react-oidc-context';

export const ProfilePicture = () => {
    const auth = useAuth();

    if (auth.isAuthenticated && auth.user) {
        const profile = auth.user.profile;
        return (
            <img alt="" src={profile.picture} className="" />
        );
    }

    return <></>;
}
