import { useErrorStore } from "../stores/useErrorStore"

/**
 * Render errors at the top of the page
 * @returns 
 */
export const ErrorMessages = () => {
    const errors = useErrorStore(state => state.errors);
    const removeError = useErrorStore(state => state.removeError);

    return <>
        {errors.map((error, idx) =>
            <div key={`error${idx}`} className="alert alert-danger alert-dismissible fade show" role="alert">
                {error}
                <button onClick={() => removeError(idx)} aria-label="Close" className="btn-close" data-bs-dismiss="alert" type="button"><span aria-hidden="true">×</span></button>
            </div>
        )}
    </>;
}