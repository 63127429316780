import create from "zustand";

export type ErrorStoreType = {

    /**
     * List of active errors
     */
    errors: string[];

    /**
     * Add an error to the page
     */
    addError: (value: string) => void;

    /**
     * Remove the error on the specified index
     */
    removeError: (idx: number) => void;

    /**
     * Remove all errors
     */
    clearErrors: () => void;
}

export const useErrorStore = create<ErrorStoreType>((set) => ({

    errors: [],

    addError: (value: string) => {      
        set((s) => ({
            errors: [
                ...s.errors,
                value
            ]
        }));
    },

    removeError: (idx: number) => {
        console.log("Remove error at index", idx);

        set((s) => ({
            errors: s.errors.filter((val, id) => idx !== id)
        }));
    },

    clearErrors: () => {
        set((s) => ({
            errors: []
        }));
    }
}));