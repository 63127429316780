import { NavLink } from "react-router-dom";

export type MenuItemProps = {

    /**
     * Title of the menu item
     */
    title: string;

    /**
     * Path of the menu item
     */
    to: string;
}

/**
 * Render a single main menu item in the sidebar
 */
export const MenuItem = (props: MenuItemProps) => {

    return <li className="slide">
        <NavLink className="side-menu__item" to={props.to}>
            <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"><path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm11-6h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm-1 6h-4V5h4v4zm-9 4H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm-1 6H5v-4h4v4zm8-6c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z" /></svg>
            <span className="side-menu__label">{props.title}</span>
        </NavLink>
    </li>
}
