import { useEffect } from "react";
import { useUIStore } from "../stores/useUIStore"

export const Home = () => {
    const uiStore = useUIStore();

    useEffect(() => {
        uiStore.setTitle("Home");
    }, []);

    return <div className="card-body">
        <div>
            <h6 className="main-content-label mb-1">Basic DataTable</h6>
            <p className="text-muted card-sub-title">Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</p>
        </div>
        <div className="table-responsive">
            {/* <Partners />
            <AuditLogs /> */}
        </div>
    </div>
}