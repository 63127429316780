import { useEffect, useState } from "react";

export const BackToTop = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", (evt) => {
            setShow(window.scrollY > 0);
        });
    });

    const onClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return <>
        {show &&
            <a href="#top" onClick={() => onClick()} id="back-to-top">
                <i className="las la-arrow-up"></i>
            </a>
        }</>;
}