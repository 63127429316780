import { useContext, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingIndicator } from "../LoadingIndicator";
import { Partner } from "../models/Partner";
import { usePartnerStore } from "../stores/usePartnerStore";
import { Sidebar } from "../ui/Sidebar"

export const EditPartner = () => {
    const [loading, setLoading] = useState(true);
    const refresh = usePartnerStore(state => state.refresh);
    const [data, setData] = useState<Partner>({
        name: "",
        partnerId: ""
    });

    const navigate = useNavigate();
    const { partnerId } = useParams();
    const auth = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    const onClose = () => {
        navigate("/partners");
    }

    const onUpdate = () => {
        updateData();
    }

    const fetchData = () => {
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${auth.user?.access_token}`);

        fetch(`${(window as any)._env_.API_URL}partners/${partnerId}`, {
            headers: headers
        })
            .then((data) => {
                return data.json();
            })
            .then((json) => {
                setData(json);
            })
            .catch((err) => {
                console.error(err);
                //addError("Could not retrieve the table information. Please try again or contact the system administrator.");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const updateData = () => {

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${auth.user?.access_token}`);
        headers.append("Content-type", "application/json");

        fetch(`${(window as any)._env_.API_URL}partners/${partnerId}`, {
            headers: headers,
            method: "PUT",
            body: JSON.stringify(data)
        })
            .then(() => {
                refresh(auth);
                onClose();
            })
            .catch((err) => {
                console.error(err);
                //addError("Could not retrieve the table information. Please try again or contact the system administrator.");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return <Sidebar title="Edit partner" onClose={() => onClose()}>
        <div className="col-lg- col-md-">
            <div className="card custom-card">
                <div className="card-body">
                    {loading && <LoadingIndicator />}
                    <div className="d-flex flex-column pd-10 pd-sm-10">
                        <div className="form-group">
                            <label>Partner Id</label>
                            <input value={data?.partnerId} className="form-control" type="text" disabled={true} />
                        </div>
                        <div className="form-group">
                            <label>Partner name</label>
                            <input value={data?.name} onChange={(v) => { setData(s => ({ ...s, name: v.target.value })) }} className="form-control" placeholder="Enter partner name" type="text" data-ms-editor="true" />
                        </div>

                        <div className="btn-list">
                            <button onClick={onClose} className="btn btn-rounded btn-light">Cancel</button>
                            <button onClick={onUpdate} className="btn btn-rounded btn-primary">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>;
}