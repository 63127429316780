import React from 'react';
import './App.css';
import { useAuth } from 'react-oidc-context';
import { ProfilePicture } from './ProfilePicture';

export const Profile = () => {
    const auth = useAuth();

    React.useEffect(() => {
        auth.events.addUserLoaded((cb) => {
            console.log(cb);
        });
    }, []);

    if (auth.isAuthenticated && auth.user) {
        const profile = auth.user.profile;
        return (
            <div className="d-flex wd-100p">
                <div className="main-img-user">
                    <ProfilePicture />
                </div>
                <div className="ms-3 my-auto">
                    <h6 className="tx-15 font-weight-semibold mb-0">{profile.given_name} {profile.family_name}</h6><span className="dropdown-title-text subtext op-6  tx-12">System Admin</span>
                </div>
            </div>
        );
    }

    return <></>;
}
