import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useEffect } from 'react';

type AuthVerificationType = {

    children: JSX.Element;
}

export const AuthVerification = (props: AuthVerificationType): JSX.Element => {
    const auth = useAuth();

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {

            // Store the active href
            const state = {
                href: window.location.href,
            };

            auth.signinRedirect({ state: state });
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    if (auth.activeNavigator) {
        return <div>Signing you in/out...</div>;
    }

    if (!auth.isAuthenticated) {
        return <div>Unable to log in</div>;
    }

    return props.children;
};
