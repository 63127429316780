import { MouseEvent, useState } from "react";

export const SearchBox = () => {
    const [active, setActive] = useState(false);

    const showSearch = (evt: MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        document.body.classList.add("search-open");
        setActive(true);
    }

    const hideSearch = (evt: MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        document.body.classList.remove("search-open");
        setActive(false);
    }

    return <li className="nav-link search-icon d-lg-none d-block">
        <form className={`navbar-form ${active && "active"}`} role="search">
            <div className="input-group">
                <input type="text" className="form-control" placeholder="Search" />
                <span className="input-group-btn">
                    <button onClick={(evt) => hideSearch(evt)} type="reset" className="btn btn-default">
                        <i className="fas fa-times"></i>
                    </button>
                    <button  onClick={(evt) => showSearch(evt)} type="submit" className="btn btn-default nav-link resp-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" className="header-icon-svgs" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>
                    </button>
                </span>
            </div>
        </form>
    </li>;
}