import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { Partner } from "../models/Partner";
import { usePartnerStore } from "../stores/usePartnerStore";
import { Sidebar } from "../ui/Sidebar"

export const CreatePartner = () => {
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [saving, setSaving] = useState(false);

    const refresh = usePartnerStore(state => state.refresh);
    const auth = useAuth();
    const [data, setData] = useState<Partner>({
        name: "",
        partnerId: ""
    });

    const onClose = () => {
        navigate("/partners");
    }

    const onCreate = () => {
        createData();
    }

    const createData = () => {
        setDisabled(true);
        setSaving(true);

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${auth.user?.access_token}`);
        headers.append("Content-type", "application/json");

        fetch(`${(window as any)._env_.API_URL}partners`, {
            headers: headers,
            method: "POST",
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (res.ok) {
                    refresh(auth);
                    onClose();
                }
            })
            .catch((err) => {
                console.error(err);
                //addError("Could not retrieve the table information. Please try again or contact the system administrator.");
            })
            .finally(() => {
                //setLoading(false);
                setDisabled(false);
                setSaving(false);
            });
    }

    return <Sidebar title="Create partner" onClose={() => onClose()}>
        <div className="col-lg- col-md-">
            <div className="card custom-card">
                <div className="card-body">
                    <div className="d-flex flex-column pd-10 pd-sm-10">
                        <div className="form-group">
                            <label>Partner name</label>
                            <input disabled={disabled} value={data.name} onChange={(evt) => { setData((s) => ({ ...s, name: evt.target.value })) }} className="form-control" placeholder="Enter partner name" type="text" data-ms-editor="true" />
                        </div>

                        <div className="btn-list">
                            <button disabled={disabled} onClick={onClose} className="btn btn-rounded btn-light">Cancel</button>
                            <button disabled={disabled} onClick={onCreate} className="btn btn-rounded btn-primary">{saving && <i className="fa fa-spin fa-spinner" />} Create</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>;
}