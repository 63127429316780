import { DarkMode } from "./ui/topbar/DarkMode"
import { Outlet } from "react-router-dom"
import { MenuItem } from "./ui/sidebar/MenuItem"
import { MenuHeader } from "./ui/sidebar/MenuHeader"
import { ProfileMenu } from "./ui/topbar/ProfileMenu"
import { PageTitle } from "./ui/PageTitle"
import { ErrorMessages } from "./ui/ErrorMessages"
import { BackToTop } from "./ui/BackToTop"
import { SearchBar } from "./ui/SearchBar"
import { Link } from "react-router-dom"
import { SearchBox } from "./ui/SearchBox"

export const AppLayout = () => {

    return <>
        <div className="page">
            <div>
                <div className="main-header side-header sticky nav nav-item">
                    <div className=" main-container container-fluid">
                        <div className="main-header-left ">
                            <div className="responsive-logo">
                                <Link to="/" className="header-logo">
                                    <img src="/assets/img/brand/logo.png" className="mobile-logo logo-1" alt="logo" />
                                    <img src="/assets/img/brand/logo-white.png" className="mobile-logo dark-logo-1" alt="logo" />
                                </Link>
                            </div>
                            <div className="app-sidebar__toggle" data-bs-toggle="sidebar">
                                <a className="open-toggle" href="javascript:void(0);"><i className="header-icon fe fe-align-left" ></i></a>
                                <a className="close-toggle" href="javascript:void(0);"><i className="header-icon fe fe-x"></i></a>
                            </div>
                            <div className="logo-horizontal">
                                <Link to="/" className="header-logo">
                                    <img src="/assets/img/brand/logo.png" className="mobile-logo logo-1" alt="logo" />
                                    <img src="/assets/img/brand/logo-white.png" className="mobile-logo dark-logo-1" alt="logo" />
                                </Link>
                            </div>
                            <SearchBar />
                        </div>
                        <div className="main-header-right">
                            <button className="navbar-toggler navresponsive-toggler d-md-none ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon fe fe-more-vertical "></span>
                            </button>
                            <div className="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
                                <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                                    <ul className="nav nav-item header-icons navbar-nav-right ms-auto">
                                        <DarkMode />
                                        {/* <li className="dropdown nav-item main-header-notification d-flex">
                                            <a className="new nav-link" data-bs-toggle="dropdown" href="javascript:void(0);">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="header-icon-svgs" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" /></svg><span className=" pulse"></span>
                                            </a>
                                            <div className="dropdown-menu">
                                                <div className="menu-header-content text-start border-bottom">
                                                    <div className="d-flex">
                                                        <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">Notifications</h6>
                                                        <span className="badge badge-pill badge-warning ms-auto my-auto float-end">Mark All Read</span>
                                                    </div>
                                                    <p className="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12 ">You have 4 unread Notifications</p>
                                                </div>
                                                <div className="main-notification-list Notification-scroll">
                                                    <a className="d-flex p-3 border-bottom" href="mail.html">
                                                        <div className="notifyimg bg-pink">
                                                            <i className="far fa-folder-open text-white"></i>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h5 className="notification-label mb-1">New files available</h5>
                                                            <div className="notification-subtext">10 hour ago</div>
                                                        </div>
                                                        <div className="ms-auto" >
                                                            <i className="las la-angle-right text-end text-muted"></i>
                                                        </div>
                                                    </a>
                                                    <a className="d-flex p-3  border-bottom" href="mail.html">
                                                        <div className="notifyimg bg-purple">
                                                            <i className="fab fa-delicious text-white"></i>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h5 className="notification-label mb-1">Updates Available</h5>
                                                            <div className="notification-subtext">2 days ago</div>
                                                        </div>
                                                        <div className="ms-auto" >
                                                            <i className="las la-angle-right text-end text-muted"></i>
                                                        </div>
                                                    </a>
                                                    <a className="d-flex p-3 border-bottom" href="mail.html">
                                                        <div className="notifyimg bg-success">
                                                            <i className="fa fa-cart-plus text-white"></i>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h5 className="notification-label mb-1">New Order Received</h5>
                                                            <div className="notification-subtext">1 hour ago</div>
                                                        </div>
                                                        <div className="ms-auto" >
                                                            <i className="las la-angle-right text-end text-muted"></i>
                                                        </div>
                                                    </a>
                                                    <a className="d-flex p-3 border-bottom" href="mail.html">
                                                        <div className="notifyimg bg-warning">
                                                            <i className="far fa-envelope-open text-white"></i>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h5 className="notification-label mb-1">New review received</h5>
                                                            <div className="notification-subtext">1 day ago</div>
                                                        </div>
                                                        <div className="ms-auto" >
                                                            <i className="las la-angle-right text-end text-muted"></i>
                                                        </div>
                                                    </a>
                                                    <a className="d-flex p-3 border-bottom" href="mail.html">
                                                        <div className="notifyimg bg-danger">
                                                            <i className="fab fa-wpforms text-white"></i>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h5 className="notification-label mb-1">22 verified registrations</h5>
                                                            <div className="notification-subtext">2 hour ago</div>
                                                        </div>
                                                        <div className="ms-auto" >
                                                            <i className="las la-angle-right text-end text-muted"></i>
                                                        </div>
                                                    </a>
                                                    <a className="d-flex p-3 border-bottom" href="mail.html">
                                                        <div className="">
                                                            <i className="far fa-check-square text-white notifyimg bg-primary"></i>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h5 className="notification-label mb-1">Project has been approved</h5>
                                                            <span className="notification-subtext">4 hour ago</span>
                                                        </div>
                                                        <div className="ms-auto" >
                                                            <i className="las la-angle-right text-end text-muted"></i>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="dropdown-footer">
                                                    <a className="btn btn-primary btn-sm btn-block" href="mail.html">VIEW ALL</a>
                                                </div>
                                            </div>
                                        </li> */}
                                        <SearchBox />
                                        <ProfileMenu />
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sticky">
                    <aside className="app-sidebar">
                        <div className="main-sidebar-header active">
                            <a className="header-logo active" href="/">
                                <img src="/assets/img/brand/logo.png" className="main-logo desktop-logo" alt="logo" />
                                <img src="/assets/img/brand/logo-white.png" className="main-logo desktop-dark" alt="logo" />
                                <img src="/assets/img/brand/favicon.png" className="main-logo mobile-logo" alt="logo" />
                                <img src="/assets/img/brand/favicon-white.png" className="main-logo mobile-dark" alt="logo" />
                            </a>
                        </div>
                        <div className="main-sidemenu">
                            <div className="slide-left disabled" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24"><path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" /></svg></div>
                            <ul className="side-menu">
                                <MenuHeader title="Administration" />
                                <MenuItem title="Partners" to="/partners" />
                                <MenuItem title="Audit log" to="/auditlog" />
                            </ul>
                        </div>
                    </aside>
                </div>
            </div>

            <div className="main-content app-content">

                <div className="main-container container-fluid">

                    <PageTitle />
                    <ErrorMessages />

                    <div className="row row-sm">
                        <div className="col-lg-12">
                            <div className="card custom-card overflow-hidden">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Page --> */}
        <BackToTop />
    </>
}