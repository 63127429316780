import { useEffect } from "react";
import { useAuditLogStore } from "../stores/useAuditlogStore";
import { useUIStore } from "../stores/useUIStore";
import { DataTable } from "../ui/elements/datatable/DataTable";
import { PageCard } from "../ui/elements/PageCard";

export const AuditLogs = () => {
    const uiStore = useUIStore();
    const auditLogStore = useAuditLogStore();

    useEffect(() => {
        uiStore.setTitle("Audit Logs");

        auditLogStore.setAttributes([
            {
                "field": "dateTime",
                "label": "Date / Time",
                "type": "datetime",
                "show": true
            },
            {
                "field": "subject",
                "label": "User Id",
                "show": true
            },
            {
                "field": "module",
                "label": "Module",
                "show": true
            },
            {
                "field": "event",
                "label": "Event",
                "show": true
            },
            {
                "field": "objectName",
                "label": "Object",
                "show": true
            }
        ]);
    }, []);

    const config = {
        title: "Audit log",
        description: "All maintenance actions that has been performed by users or admins can be found in the overview below."
    }

    return <PageCard {...config}>
        <DataTable store={auditLogStore} />
    </PageCard>
}