import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { usePartnerStore } from "../stores/usePartnerStore";
import { useUIStore } from "../stores/useUIStore";
import { DataTable } from "../ui/elements/datatable/DataTable";
import { PageCard } from "../ui/elements/PageCard";

export const Partners = () => {
    const setTitle = useUIStore(state => state.setTitle);
    const navigate = useNavigate();
    const partnerStore = usePartnerStore();

    useEffect(() => {
        setTitle("Partners");

        partnerStore.setAttributes([
            {
                field: "name",
                label: "Name",
                show: true,
                hyperlink: true,
                onClick: (record) => { onNameClick(record) }
            },
            {
                field: "partnerId",
                label: "Partner Id",
                show: true
            },
            {
                field: "",
                label: "Actions",
                show: true,
                type: "actions",
                actions: [{
                    onClick: (record) => { onVisitClick(record); },
                    title: "Partner portal"
                }]
             }
        ]);
    }, []);

    const onNameClick = (record: any) => {
        navigate(record["partnerId"]);
    }

    const onVisitClick = (record: any) => {
        window.open(`https://partner.geoapps.dev/${record["partnerId"]}`);
    }

    const config = {
        title: "Partners",
        description: "A list of GeoApps partners that provide GeoApps as a service. Partners can be using separate user accounts and domains when they applied for whitelabel."
    }

    const actionButtons = <Link className="btn btn-rounded btn-primary" to="create"><i className="fa fa-plus"></i> Create</Link>

    return <>
        <PageCard {...config}>
            <DataTable store={partnerStore} buttons={actionButtons} />
        </PageCard>
        <Outlet />
    </>
}
