export type MenuHeaderProps = {

    /**
     * Title of the menu item
     */
    title: string;
}

/**
 * Render a single header (category) above certain menu items
 */
export const MenuHeader = (props: MenuHeaderProps) => {

    return <li className="side-item side-item-category">{props.title}</li>
}
